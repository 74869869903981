<template>
    <div class="match_full_detail">
        <div class="text-center" v-if="!is_data_ready">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div v-else-if="tournaments.length == 0 && is_data_ready">
            {{$t('football.No match available')}}
        </div>
        <MatchCard :tournaments="tournaments" v-else></MatchCard>
    </div>
</template>

<script>
    import MatchCard from './match-card';
    import moment from "moment";
    export default {
        name: "match-listing",
        metaInfo() {
            if (this.$store.state.siteSetting) {

                let ss = this.$store.state.siteSetting;
                var replacements = {
                    '%DATE%': (this.$route.params.date == moment(new Date()).format("YYYY-MM-DD")) ? 'Today' : this.$route.params.date,
                    '%TAB%': this.$getSafe(() => this.$route.params.type.charAt(0).toUpperCase() + this.$route.params.type.substring(1)),
                };

                let title = this.$getSafe(() => this.$replacePlaceHolders(ss.football_listing_meta_title,replacements));
                let description = this.$getSafe(() => this.$replacePlaceHolders(ss.football_listing_meta_description,replacements));
                let keywords = this.$getSafe(() => ss.football_listing_meta_keywords);

                // my matches
                let routeName = this.$route.name.substring((this.$route.name.indexOf('.') + 1), this.$route.name.length);
                if (routeName == 'myMatches') {
                    title = this.$getSafe(() => ss.football_my_matches_meta_title);
                    description = this.$getSafe(() => football_my_matches_meta_description);
                    keywords = this.$getSafe(() => football_my_matches_meta_keywords);
                }

                return {
                    title: title,
                    description: description,
                    meta: this.$getMetaTags(title, description, keywords),
                    link: [
                        {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}],
                };
            }
        },
        data() {
            return {
                tournaments: [],
                last_updated: new Date(),
                polling: null,
            }
        },
        components: {
            MatchCard
        },
        watch: {
            '$route.params.date'() {
                this.getMatches();
            },
            '$route.params.type'() {
                this.getMatches();
            }
        },
        mounted() {
            this.getMatches();

            var _this = this;
            this.$root.$on('FBMatchUpdated', data => {
                let time_now = new Date();
                if ((time_now - _this.last_updated) > 7000) {
                    if (_this.findMatchByID(data.match_id)) {
                        _this.getMatches(true)
                    }
                }
            });
            this.$root.$on('FBMatchesUpdated', data => {
                let time_now = new Date();
                if ((time_now - _this.last_updated) > 7000) {
                    if (_this.findMatchByIDs(data.match_ids)) {
                        _this.getMatches(true)
                    }
                }
            });
        },
        created() {
            this.pollData();
        },
        beforeDestroy() {
            this.$root.$off('FBMatchUpdated')
            this.$root.$off('FBMatchesUpdated')
            clearInterval(this.polling);
        },
        serverPrefetch() {
            return this.getMatches();
        },
        methods: {
            pollData() {
                this.polling = setInterval(() => {
                    this.iterationMatches();
                }, 60000);
            },
            // calculate minutes
            iterationMatches() {
                for (let i = 0; i < this.tournaments.length; i++) {
                    for (let j = 0; j < this.tournaments[i].matches.length; j++) {
                    if (this.tournaments[i].matches[j]) {
                        let minutes = this.minutesCalculate(
                        this.tournaments[i].matches[j].status_update_name,
                        this.tournaments[i].matches[j].status_update_time,
                        this.tournaments[i].matches[j].interrupted_minutes,
                        );
                        if (minutes) {
                        this.tournaments[i].matches[j].minutes = minutes;
                        }
                    }
                    }
                }
            },
            minutesCalculate(statusName, statusUpdatedateTime, interruptedMinutes) {
                if (statusUpdatedateTime && statusUpdatedateTime !== '' && statusName && statusName !== '') {
                    let currentTime = moment(new Date());
                    let statusTime = moment(statusUpdatedateTime);
                    let diff = currentTime.diff(statusTime, 'minutes');
                    // let diff = currentTime - statusTime;
                    if (diff < 0) {
                        return false;
                    }

                    let totalCalMinutes = diff + parseInt(interruptedMinutes || 0, 10);//Math.ceil(diff / 1000 / 60) + parseInt(interruptedMinutes || 0, 10);

                    if (statusName == '1st Half' || statusName == '2nd Half' || statusName == 'ET 1st Half' || statusName == 'ET 2nd Half' || statusName == 'Penalty') {
                        if (statusName == '1st Half') {
                            if (totalCalMinutes > 45) {
                                return 45 + '+';
                            } else {
                                return totalCalMinutes + "'";
                            }
                        }
                        if (statusName == '2nd Half') {
                            if (totalCalMinutes + 46 > 90) {
                                return 90 + '+'; // +46
                            } else {
                                return totalCalMinutes + 46 + "'"; // +46
                            }
                        }
                        if (statusName == 'ET 1st Half') {
                            if (totalCalMinutes + 91 > 105) {
                                return 105 + '+';
                            } else {
                                return totalCalMinutes + 91 + "'"; // +91
                            }
                        }
                        if (statusName == 'ET 2nd Half') {
                            if (totalCalMinutes + 106 > 120) {
                                return 106 + '+';
                            } else {
                                return totalCalMinutes + 106 + "'"; // +106
                            }
                        }
                        if (statusName == 'Penalty') {
                            return 'Pen';
                        }
                    } else {
                        return '';
                    }
                }
            },
            getMatches(is_socket = false) {
                let url = ''
                this.last_updated = new Date();
                let params = {date: this.$route.params.date, type: this.$route.params.type}
                if (this.$route.name == 'football.myMatches') {
                    url = this.$FB_FAVOIRTE_MATCHES_API;
                    let favourites = this.$store.getters.fbFavouriteMatches.length > 0 ? this.$store.getters.fbFavouriteMatches : ['']
                    delete params.date;
                    delete params.type;
                    params.matches = favourites;
                } else {
                    url = this.$FB_MATCHES_API;
                }
                if (!is_socket) this.is_data_ready = false;
                return this.$axios
                        .get(url, {params})
                        .then(response => {
                            if (response.data) {
                                this.tournaments = response.data.tournaments;
                                this.is_data_ready = true;
                                this.iterationMatches();
                            }
                        })
                        .catch(error => console.log(error))
            },
            findMatchByID(id) {
                let isFound = false;
                for( let i = 0, len = this.tournaments.length; i < len; i++ ) {
                    for (let i2 = 0, len2 = this.tournaments[i].matches.length; i2 < len2; i2++) {
                        if (this.tournaments[i].matches[i2].id == id) {
                            isFound = true;
                            break;
                        }
                    }
                    if (isFound) break;
                }
                return isFound;
            },
            findMatchByIDs(ids) {
                let isFound = false;
                for( let i = 0, len = this.tournaments.length; i < len; i++ ) {
                    for (let i2 = 0, len2 = this.tournaments[i].matches.length; i2 < len2; i2++) {
                        if (ids.includes(this.tournaments[i].matches[i2].id)) {
                            isFound = true;
                            break;
                        }
                    }
                    if (isFound) break;
                }
                return isFound;
            }
        }
    }
</script>
